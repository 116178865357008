.title-bar {
    position: fixed;
    left: var(--distance-to-left);
    top: 5px;
    color: white;
    line-height: var(--title-bar-height);
    height: var(--title-bar-height);
    width: var(--total-blog-width);
    font-family: sans-serif;
    background-image: url("header_desktop.png");
    background-size: calc(0.4 * var(--total-blog-width));
    background-repeat: no-repeat;
    background-color: black;
    background-position: center;
    text-shadow: 5px 5px black;
    z-index: 2;
    cursor: pointer;
}

.title-bar span {
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .title-bar {
        background-image: url("header_mobile.png");
        background-size: calc(0.6 * var(--total-blog-width));
    }
}

@media print {
    .title-bar {
        display: none;
    }
}
