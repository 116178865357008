:root {
    --total-blog-width: calc(4 * var(--menu-item-width));
    --menu-item-width: calc(60vw / 4);
    --height-of-menu-items: 32px;
    --title-bar-height: 75px;
    --distance-to-left: calc(100vw / 2 - var(--total-blog-width) / 2);
    --main-theme-color: maroon;
}

.text-content {
    position: relative;
    top: calc(var(--title-bar-height) + var(--height-of-menu-items) + 5px);
    left: var(--distance-to-left);
    width: calc(var(--total-blog-width) - 15px);
}

code {
    font-family: monospace !important;
    background-color: #ececec;
}

@media screen and (max-width: 800px) {
    .text-content {
        top: calc(var(--title-bar-height) + 5px);
    }

    :root {
        --menu-item-width: 90vw;
        --total-blog-width: var(--menu-item-width);
        --title-bar-height: 50px;
    }
}
