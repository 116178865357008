.menu-item {
    background-color: black;
    text-align: center;
    font-size: 18px;
    color: white;
    position: fixed;
    top: calc(var(--title-bar-height) + 5px);
    font-family: sans-serif;
    border-top: 1px solid gray;
    width: var(--menu-item-width);
    height: var(--height-of-menu-items);
    line-height: var(--height-of-menu-items);
    z-index: 2;
    cursor: pointer;
}

.menu-item:hover {
    background-image: linear-gradient(black, var(--main-theme-color));
}

.menu {
    position:fixed;
    width: var(--total-blog-width);
    float: none;
    height: var(--height-of-menu-items);
    z-index: 2;
    background-color: var(--main-theme-color);
    top: calc(var(--title-bar-height) + 5px);
    left: var(--distance-to-left);
}

.burger-menu {
    width: var(--title-bar-height);
    height: var(--title-bar-height);
    position: fixed;
    top: 5px;
    left: calc(var(--distance-to-left) + var(--total-blog-width) - var(--title-bar-height));
    display: none;
    z-index: 2;
}

.burger-menu .burger-menu-bar {
    width: calc(0.8 * var(--title-bar-height));
    height: calc(var(--title-bar-height) / 7);
    background-color: white;
    border-radius: 15%;
    margin-top: calc(var(--title-bar-height) / 7);
}

@media screen and (max-width: 800px) {
    .menu {
        display: none;
    }

    .menu-item {
        font-size: 12px;
        left: var(--distance-to-left);
        z-index: 2;
    }

    .burger-menu {
        display: block;
    }
}

@media print {
    .parent-menu {
        display: none;
    }
}
